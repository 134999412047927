.button-filter-open-drawer-style {
  color: #baaee5;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -1%;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
}

.filters-drawer {
  display: flex;
  align-items: center;
  overflow-y: hidden;

  .ant-drawer-body {
    padding: 0px;
  }

  .ant-drawer-header {
    display: flex;
    align-items: center;
    background-color: #241d38;
    height: 70px;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
  }

  .ant-drawer-title {
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    line-height: 17px;
  }

  .ant-drawer-close {
    color: #fff;
    font-size: 20px;
    padding: 0px;
    line-height: 70px;
    margin-right: 10px;
  }

  .container-apply-button-styled {
    display: flex;
    justify-content: center;
    padding: 20px
  }

  .title-filters-container {
    display: flex;
    align-items: center;
    background-color: #e7e7e7;
    height: 30px;
    padding: 0px;
    margin-bottom: 10px;

    .title-filters-style {
      color: #768190;
      font-size: 14px;
      font-weight: 500;
      line-height: 12px;
      padding-left: 10px;
    }
  }

  .tree-select-container {
    padding: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    grid-gap: 3px;
    gap: 3px;

    .label {
      font-weight: 500;
    }

    .ant-select-selection-item-remove {
      align-self: center;
      justify-content: center;
      display: flex;
    }
  }

  .dynamic-filters {
    overflow-y: auto;
    max-height: 250px;

    .dynamic-filter-container {
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 5px;
      display: flex;
      flex-direction: column;
      grid-gap: 3px;
      gap: 3px;
  
      .label {
        font-weight: 500;
      }
  
      .ant-select-selection-item-remove {
        align-self: center;
        justify-content: center;
        display: flex;
      }
    }
  }

  .container-selected-range-dates {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    background-color: #371a4d;
    height: 45px;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 10px;

    .title-selected-range-dates {
      color: #baaee5;
      font-size: 15px;
      font-weight: 500;
      line-height: 30px;
    }

    .container-range-dates {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;

      .button-style-range-date {
        color: #fdfdfd;
        font-size: 15px;
        font-weight: 500;
        line-height: 30px;
        margin-right: 15px;
        cursor: pointer;
        -webkit-user-select: none;
                user-select: none;
      }
    }
  }

  .container-title-date-picker {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .title-style-range-filter-date-picker {
      font-size: 13px;
      font-weight: 500;
      color: #baaee5;
      line-height: 16px;
    }
  }

  .container-date-picker-style {
    display: flex;
    align-items: center;

    .lmt-filter-bottom-bar-date-picker {
      background-color: transparent;
      border: none;
      cursor: pointer;
      -webkit-user-select: none;
              user-select: none;

      .ant-picker-input > input {
        border-radius: 0 !important;
        color: #575757;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        border: none;
        text-align: center;
        cursor: pointer;
        -webkit-user-select: none;
                user-select: none;
      }

      .ant-picker-separator {
        border-right: 2px solid #baaee5;
        height: 30px;
        color: transparent;
      }

      .ant-picker-active-bar {
        margin-left: 15px !important;
        background-color: #baaee5 !important;
      }
    }
  }
}
