.lmt-epsilon-header {
  position: sticky;
  background-color: #1d172d;
  top: 0;
  z-index: 200;
  width: 100%;
  flex-direction: column;
  height: 18%;
  padding: 0;

  .epsilon-main-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-height: 65px;
    border-bottom: 1px solid #3f3166;

    .epsilon-header-logos {
      display: flex;
      flex-direction: row;
      grid-gap: 10px;
      gap: 10px;
      justify-content: center;

      .limetropy-logo img {
        width: 50px;
        height: 50px;
        object-fit: cover;
      }

      .client-logo img {
        width: 35px;
        height: 35x;
        object-fit: cover;
      }
    }

    .epsilon-header-actions {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 15px;
      height: 60px;

      .epsilon-header-language {
        border-left: 1px solid #3f3166;
        padding-left: 15px;
        height: 100%;
      }

      .epsilon-header-alerts {
        border-left: 1px solid #3f3166;
        padding-left: 15px;
        padding-right: 15px;
        height: 100%;
      }

      .epsilon-header-dropdown {
        border-left: 1px solid #3f3166;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        padding-left: 15px;
        padding-right: 15px;
        height: 100%;
        padding-top: 2%;

        .text-profile-style {
          color: #ffffff;
          font-size: 14px;
          line-height: 21px;
          margin-right: 15px;
          font-weight: 300;
        }
        .epsilon-header-dropdown-icon-container {
          width: 30px;
          height: 30px;
          border-radius: 6px;
          background-color: rgba(14, 4, 43, 0.4);
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  .epsilon-sub-header {
    width: 100%;
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    align-items: center;
    max-height: 50px;

    .survey-count {
      font-size: 14px;
      color: #baaee5;
      margin-right: 15px;
    }
  }

  .epsilon-selected-filters-header {
    border-top: 1px solid #3f3166;
    max-height: 40px;
    line-height: 40px;
    color: #baaee5;
    padding-left: 5%;
  }
}
